import debug from '../package/debug/debug'
import Swiper from 'swiper';

const pic = () => {
  var picSwiper = new Swiper('.pic .swiper-container', {
    effect: 'fade',
    pagination: {
      el: '.pic .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
    loop: true,
    speed: 3000,
    autoplay: true
  });
}
const init = (callback) => {
  callback(0);
  debug('index is load');
  // banner();
  pic();
};

const index = {
  init: init
};

export default index;
