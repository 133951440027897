import debug from '../package/debug/debug'
import Swiper from 'swiper';

const init = (callback) => {
  callback(5);
  debug('contact is load');

};

const index2 = {
  init: init
};

export default index2;
